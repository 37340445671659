body {
  background-color: #fff8ef !important;
  margin: 0;
  /* font-family: Syne, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Neue Power Bold";
  /* src: url('neue-power-bold-webfont.woff2') format('woff2'), */
  src: url("../assets//fonts/neue-power-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Neue Power Ultra";
  /* src: url('neue-power-bold-webfont.woff2') format('woff2'), */
  src: url("../assets//fonts/neue-power-ultra.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Neue Power Medium";
  /* src: url('neue-power-bold-webfont.woff2') format('woff2'), */
  src: url("../assets//fonts/neue-power-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.content {
  background: #fff8ef;
}

.top-header {
  z-index: -1;
  height: 150px;
  width: 100%;
  background-image: url("../assets/HeaderPattern.svg");
  background-size: cover;
}

textarea,
input,
select {
  outline: none;
}

*:focus {
  outline: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Select */
.form-input {
  width: "100%";
  height: "30px";
  font-size: "0.85rem";
  border-top: "none";
  border-left: "none";
  border-right: "none";
  border-bottom: "1px solid #e5a428";
  border-radius: "0";
  padding: "0";
  color: "#000";
}

/* Slider */
.slider-container {
  margin: 0.7rem 0;
  flex-direction: column;
}

/* React Date Picker */
.react-datepicker-wrapper {
  font-size: 0.85rem !important;
  line-height: 0.85rem !important;
  border-bottom: 1px solid #e5a428 !important;
}

.react-datepicker__header {
  background-color: #fbe1bf !important;
  border-bottom: 1px solid #e5a428 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #fbe1bf !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #e5a428 !important;
}

.react-datepicker__header {
  height: 60px !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 1rem !important;
  background-color: #e5a428 !important;
}

.react-datepicker__navigation--previous {
  border-right-color: transparent !important;
}

.react-datepicker__day--selected {
  border-radius: 1rem !important;
  background-color: #e5a428 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #e5a428 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #fbe1bf !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e5a428 !important;
}

.react-datepicker__day:hover {
  background-color: #fbe1bf !important;
  border-radius: 1rem !important;
}

.react-datepicker {
  border: 1px solid #e5a428 !important;
}

input {
  background: transparent !important;
  outline: none !important;
}

input::placeholder {
  color: #000;
  font-size: 0.85rem;
  padding-bottom: 0;
}

input:focus {
  outline: none;
  -webkit-focus-ring-color: transparent;
}

/* Input Container */
.input-container {
  background: "#fff8ef";
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #e5a428;
}
/* Heading */
.fixture-heading {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #e5a428;
  color: transparent;
  text-align: "center";
  font-family: "Neue Power Ultra";
}

.fixture-heading__rhestsub-cricket {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #2a512e;
  color: transparent;
  text-align: "center";
  font-family: "Neue Power Ultra";
}

.fixture-heading__hwb {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #ffde17;
  color: transparent;
  text-align: "center";
  font-family: "Neue Power Ultra";
}

.fixture-heading__kick-off {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  text-align: "center";
  font-family: "Neue Power Ultra";
}

.fixture-heading__soccer-laduma {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #e2c67e;
  color: transparent;
  text-align: "center";
  font-family: "Neue Power Ultra";
}

.horizontal-pattern {
  z-index: -1;
}

.contact-icon {
  font-size: 1.5rem;
}

.contact-icon__footer {
  font-size: 1.25rem;
}

.contact-icon:hover,
.contact-icon__footer:hover {
  color: #e5a428;
}

/* Menu */

.menu-item-container {
  background: #faf9f6;
  margin: 1rem 0;
  position: relative;
  width: 100%;
}

.menu-item-container:nth-child(1) {
  margin-top: 0;
}

.menu-item-container::after {
  content: "";
  width: 0;
  height: 1px;
  background: #e5a428;
  position: absolute;
  top: -8px;
  left: 0;
  transition: all 0.6s;
}

.menu-item-container::before {
  content: "";
  width: 0;
  height: 1px;
  background: #e5a428;
  position: absolute;
  bottom: -8px;
  left: 0;
  transition: all 0.6s;
}

.menu-item-container:hover {
  background: #faf9f6;
}

.menu-item-container:hover::after {
  width: 100%;
}

.menu-item-container:hover::before {
  width: 100%;
}

.menu-item-container:hover .menu-item__description {
  color: #e5a428;
  transition: color 0.3s ease;
}

.menu-item__description.active {
  color: #e5a428;
}

.react-datepicker__day--in-range {
  border-radius: 1rem !important;
  background-color: #e5a428 !important;
  color: #fff;
}

/* RHESTSub Artwork */
.rhestsub-rugby-flag-size {
  height: 60px;
}

.rhestsub-single-stat-container {
  justify-content: "space-between";
  align-items: "baseline";
}

/* .container { 
  visibility: hidden;
} */
/* Home Header */
.img-container {
  content: "";
  z-index: -1;
  height: 600px;
  width: 401px;
  top: 125px;
  right: 50px;
  position: absolute;
  overflow: hidden;
  background: #fff8ef;
}

/* Kick Off */
.sl-left-player-image {
  position: absolute;
  height: 510px;
  bottom: 24;
  z-index: 1;
  right: -30;
}

#file-upload-button {
  background-color: pink !important;
}
